'use client'

import type { Dispatch, SetStateAction } from 'react'
import type { FilterOption, FilterOnOff } from '../../components/profile/FilterContentMenu'
import type { SetOptional } from 'type-fest'
import { useMemo, useState } from 'react'
import { useSession } from 'next-auth/react'
import { useConstantsContext } from '../contexts/ConstantsContext'
import { defaultCompareFn } from './useWatchVariable'

const allCards: readonly FilterOption<DTO.ProfileCards>[] = [
    { type: 'locs', title: 'LOCS' },
    { type: 'positions', title: 'Positions' },
    { type: 'skills', title: 'Skills' },
    { type: 'intensity', title: 'Intensity Grades' },
    { type: 'scoutingSummary', title: 'Scouting Summary' },
    { type: 'salary', title: 'Salary' },
    { type: 'similarPlayers', title: 'Similar Players' },
    { type: 'measurements', title: 'Measurements' },
    { type: 'sameAgents', title: 'Same Agents' },
    { type: 'intel', title: 'Intel Summaries' },
    { type: 'injuryHistory', title: 'Injury History' },
    { type: 'shooting', title: 'Shooting' },
    { type: 'scoring', title: 'Scoring' },
    { type: 'boxscore', title: 'Box Score' },
] as const

const allCalloutBoxes: readonly FilterOption<DTO.ProfileCalloutBoxes>[] = [
    { type: 'scoutingNow', title: 'Scouting Now' },
    { type: 'scoutingBull', title: 'Scouting Bullseye' },
    { type: 'insightsNow', title: 'Insights Now' },
    { type: 'intensityGrade', title: 'Intensity Grade' },
    { type: 'draftRange', title: 'Draft Range' },
    { type: 'draftRank', title: 'Scouting Rank' },
    { type: 'workout', title: 'Workout' },
    { type: 'interview', title: 'Interview' },
]

export type ProfileContentPlayer = SetOptional<
    Pick<DTO.Player, 'isNBAPlayer' | 'finalSeason' | 'isGLeaguePlayer' | 'urlSlug' | 'isSLPlayer'>,
    'isGLeaguePlayer' | 'urlSlug' | 'isSLPlayer'
>
export type ProfileCardSelection = Partial<Record<DTO.ProfileCards, boolean>>
export type ProfileCalloutBoxSelection = Partial<Record<DTO.ProfileCalloutBoxes, boolean>>

const getAllCardsAsActive = (cards: FilterOption<DTO.ProfileCards>[]): ProfileCardSelection =>
    cards.reduce<FilterOnOff<DTO.ProfileCards>>((acc, { type }) => ({ ...acc, [type]: true }), {})

const getAllCalloutBoxesAsActive = (cards: FilterOption<DTO.ProfileCalloutBoxes>[]): ProfileCalloutBoxSelection =>
    cards.reduce<FilterOnOff<DTO.ProfileCalloutBoxes>>((acc, { type }) => ({ ...acc, [type]: true }), {})

const filterInitCards = (
    initCards: ProfileCardSelection | undefined,
    availableCards: FilterOption<DTO.ProfileCards>[]
): ProfileCardSelection | undefined => {
    if (!initCards) return undefined
    const filteredInitCards = {} as ProfileCardSelection
    Object.entries(initCards).forEach(([card, active]) => {
        if (availableCards.find((c) => c.type === card)) filteredInitCards[card as DTO.ProfileCards] = active
    })
    return filteredInitCards
}

const filterInitCallouts = (
    initCards: ProfileCalloutBoxSelection | undefined,
    availableCallouts: FilterOption<DTO.ProfileCalloutBoxes>[]
): ProfileCalloutBoxSelection | undefined => {
    if (!initCards) return undefined
    const filteredInitCallouts = {} as ProfileCalloutBoxSelection
    Object.entries(initCards).forEach(([card, active]) => {
        if (availableCallouts.find((c) => c.type === card))
            filteredInitCallouts[card as DTO.ProfileCalloutBoxes] = active
    })
    return filteredInitCallouts
}

const useProfileContent = (
    level: Enum.PlayerLevel | null,
    player: ProfileContentPlayer | undefined,
    initCards?: ProfileCardSelection,
    initCallouts?: ProfileCalloutBoxSelection
): readonly [
    FilterOption<DTO.ProfileCards>[],
    Partial<Record<DTO.ProfileCards, boolean>>,
    Dispatch<SetStateAction<Partial<Record<DTO.ProfileCards, boolean>>>>,
    FilterOption<DTO.ProfileCalloutBoxes>[],
    Partial<Record<DTO.ProfileCalloutBoxes, boolean>>,
    Dispatch<SetStateAction<Partial<Record<DTO.ProfileCalloutBoxes, boolean>>>>,
    boolean,
] => {
    const { data: session } = useSession()
    const { profileYear } = useConstantsContext()

    const isNBAPlayer = player?.isNBAPlayer
    const isGLeaguePlayer = player?.isGLeaguePlayer || player?.isSLPlayer
    const finalSeason = player?.finalSeason

    // filter cards based on player level & permissions
    // set all cards as selected whenever a new version of "cards" is created
    // https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
    const [cards, callouts, isRecentNBAPlayer] = useMemo(() => {
        const cp = session?.roles.contentPermissions || {}
        const displayPositionVersatility = !!(
            level === 'PRO' &&
            isNBAPlayer &&
            finalSeason &&
            finalSeason >= (profileYear - 1).toString()
        )
        const gLeagueOnlyPerms = cp['player-profile-glg']
        const availableCards = [...allCards].filter((c) => {
            if (c.type === 'locs') return (!gLeagueOnlyPerms || isGLeaguePlayer) && cp.locs
            if (c.type === 'skills') return (!gLeagueOnlyPerms || isGLeaguePlayer) && cp.skills
            if (c.type === 'positions')
                return (displayPositionVersatility && cp.positions) || cp['position-versatility']
            if (c.type === 'intensity')
                return (!gLeagueOnlyPerms || isGLeaguePlayer) && level !== 'AM' && cp['intensity-grades']
            if (c.type === 'salary') return isNBAPlayer && level !== 'AM' && cp['player-salary-info']
            if (c.type === 'similarPlayers') return level === 'PRO' && cp['player-salary-info']
            if (c.type === 'measurements') return cp.measurements
            if (c.type === 'sameAgents')
                return isNBAPlayer && level !== 'AM' && cp['player-representation-info'] && cp['player-salary-info']
            if (c.type === 'intel')
                return level === 'AM' && (!gLeagueOnlyPerms || isGLeaguePlayer) && cp['intensity-grades']
            if (c.type === 'scoutingSummary')
                return level === 'AM' && (!gLeagueOnlyPerms || isGLeaguePlayer) && cp['scouting-summary']
            if (c.type === 'injuryHistory') return cp['injury-history']
            return true
        })

        const availableCallouts = [...allCalloutBoxes].filter((c) => {
            if (player?.urlSlug) return false
            if (c.type === 'scoutingBull') return (!gLeagueOnlyPerms || isGLeaguePlayer) && cp.locs
            if (c.type === 'scoutingNow' || c.type === 'insightsNow')
                return level === 'PRO' && (!gLeagueOnlyPerms || isGLeaguePlayer) && cp.locs
            if (c.type === 'intensityGrade') return (!gLeagueOnlyPerms || isGLeaguePlayer) && cp['intensity-grades']
            if (c.type === 'draftRange') return level === 'AM' && cp['mock-draft-model']
            if (c.type === 'workout' || c.type === 'interview') return level === 'AM' && cp['player-draft-info']
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (c.type === 'draftRank') return level === 'AM' && cp['player-draft-info']
            return true
        })

        return [availableCards, availableCallouts, displayPositionVersatility]
    }, [
        session?.roles.contentPermissions,
        level,
        isNBAPlayer,
        finalSeason,
        profileYear,
        isGLeaguePlayer,
        player?.urlSlug,
    ])

    const [prevCards, setPrevCards] = useState<FilterOption<DTO.ProfileCards>[]>(cards)

    const [activeCards, setActiveCards] = useState<ProfileCardSelection>(initCards || getAllCardsAsActive(cards))
    if (!defaultCompareFn(cards, prevCards)) {
        setPrevCards(cards)
        setActiveCards(filterInitCards(initCards, cards) || getAllCardsAsActive(cards))
    }

    const [prevCallouts, setPrevCallouts] = useState<FilterOption<DTO.ProfileCalloutBoxes>[]>(callouts)
    const [activeCallouts, setActiveCallouts] = useState<ProfileCalloutBoxSelection>(
        initCallouts || getAllCalloutBoxesAsActive(callouts)
    )
    if (!defaultCompareFn(callouts, prevCallouts)) {
        setPrevCallouts(callouts)
        setActiveCallouts(filterInitCallouts(initCallouts, callouts) || getAllCalloutBoxesAsActive(callouts))
    }

    return [cards, activeCards, setActiveCards, callouts, activeCallouts, setActiveCallouts, isRecentNBAPlayer] as const
}

export default useProfileContent
