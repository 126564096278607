'use client'

import type { Dispatch, SetStateAction } from 'react'
import type { FilterOption, FilterOnOff } from '../../components/profile/FilterContentMenu'
import { useMemo, useState } from 'react'
import { useSession } from 'next-auth/react'
import { defaultCompareFn } from './useWatchVariable'

export type TeamProfileCardSelection = Partial<Record<DTO.TeamProfileCards, boolean>>

const getAllCardsAsActive = (cards: FilterOption<DTO.TeamProfileCards>[]): TeamProfileCardSelection =>
    cards.reduce<FilterOnOff<DTO.TeamProfileCards>>((acc, { type }) => ({ ...acc, [type]: true }), {})

const filterInitCards = (
    initCards: TeamProfileCardSelection | undefined,
    availableCards: FilterOption<DTO.TeamProfileCards>[]
): TeamProfileCardSelection | undefined => {
    if (!initCards) return undefined
    const filteredInitCards = {} as TeamProfileCardSelection
    Object.entries(initCards).forEach(([card, active]) => {
        if (availableCards.find((c) => c.type === card)) filteredInitCards[card as DTO.TeamProfileCards] = active
    })
    return filteredInitCards
}

const allCards: readonly FilterOption<DTO.TeamProfileCards>[] = [
    { type: 'strategy', title: 'Strategy' },
    { type: 'notes', title: 'Notes' },
    { type: 'depth-chart', title: 'Depth Charts' },
    { type: 'salary', title: 'Salary Cap' },
    { type: 'minutes-distribution', title: 'Minutes Distribution' },
    { type: 'player-eval', title: 'Player Evaluation' },
    { type: 'draft-assets', title: 'Draft Assets' },
] as const

const useTeamProfileContent = (
    league: Enum.DraftLeague | undefined,
    initCards?: TeamProfileCardSelection
): readonly [
    FilterOption<DTO.TeamProfileCards>[],
    Partial<Record<DTO.TeamProfileCards, boolean>>,
    Dispatch<SetStateAction<Partial<Record<DTO.TeamProfileCards, boolean>>>>,
] => {
    const { data: session } = useSession()

    const cards = useMemo(() => {
        const fp = session?.roles.featurePermissions || {}

        const availableCards = [...allCards].filter((c) => {
            if (c.type === 'depth-chart') return fp['depth-charts']
            if (c.type === 'draft-assets')
                return (league === 'NBA' && fp['league-draft']) || (league === 'G-League' && fp['league-draft-glg'])
            if (c.type === 'minutes-distribution') return fp['team-profile']
            if (c.type === 'salary') return league === 'NBA' && fp.contracts
            if (c.type === 'strategy') return fp['team-profile']
            if (c.type === 'notes') return fp['team-profile']
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (c.type === 'player-eval') return fp['player-stats']
            return true
        })

        return availableCards
    }, [league, session?.roles.featurePermissions])

    const [prevCards, setPrevCards] = useState<FilterOption<DTO.TeamProfileCards>[]>(cards)

    const [activeCards, setActiveCards] = useState<TeamProfileCardSelection>(
        filterInitCards(initCards, cards) || getAllCardsAsActive(cards)
    )
    if (!defaultCompareFn(cards, prevCards)) {
        setPrevCards(cards)
        setActiveCards(initCards || getAllCardsAsActive(cards))
    }

    return [cards, activeCards, setActiveCards] as const
}

export default useTeamProfileContent
