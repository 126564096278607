'use client'

import type { Dispatch } from 'react'
import { useCallback, useReducer } from 'react'
import useToastContext from './useToastContext'
import { useEditDepthChartItems } from './useDepthCharts'

export type DepthChartActions = 'add' | 'move' | 'edit'
export type DepthChartItems = {
    add: DTO.DepthChartItemToEdit | null
    edit: DTO.DepthChartItemToEdit | null
    move: DTO.DepthChartItemToEdit | null
}

type UseDepthChartHelpersReturn = {
    selectedItems: DepthChartItems
    setSelectedItems: Dispatch<{
        type: DepthChartActions
        item: DTO.DepthChartItemToEdit | null
    }>
    handleMoveItem: (selectedItem: DTO.DepthChartItemToEdit, newLocation: DTO.DepthChartItemToEdit) => void
}

const useDepthChartHelpers = (depthChart: DTO.DepthChartResp | undefined): UseDepthChartHelpersReturn => {
    const [selectedItems, setSelectedItems] = useReducer(
        (
            state: {
                add: DTO.DepthChartItemToEdit | null
                edit: DTO.DepthChartItemToEdit | null
                move: DTO.DepthChartItemToEdit | null
            },
            action: { type: DepthChartActions; item: DTO.DepthChartItemToEdit | null }
        ) => {
            switch (action.type) {
                case 'add': {
                    return {
                        ...state,
                        add: action.item,
                    }
                }
                case 'move': {
                    return {
                        ...state,
                        move: action.item,
                    }
                }
                case 'edit': {
                    return {
                        ...state,
                        edit: action.item,
                    }
                }
                default:
                    return state
            }
        },
        {
            add: null,
            edit: null,
            move: null,
        }
    )

    const toastContext = useToastContext()
    const { mutate: move } = useEditDepthChartItems()

    const handleMoveItem = useCallback(
        (item: DTO.DepthChartItemToEdit, newLocation: DTO.DepthChartItemToEdit): void => {
            if (
                item.depthChartId === newLocation.depthChartId &&
                item.position === newLocation.position &&
                item.rank === newLocation.rank &&
                item.teamId === newLocation.teamId
            ) {
                setSelectedItems({ type: 'move', item: null })
                setSelectedItems({ type: 'edit', item: null })
            } else if (depthChart) {
                const existingItem = depthChart.items.find(
                    (d) =>
                        d.depthChartId === newLocation.depthChartId &&
                        d.position === newLocation.position &&
                        d.rank === newLocation.rank &&
                        d.teamId === newLocation.teamId
                )
                const currentItem = depthChart.items.find(
                    (d) =>
                        d.depthChartId === item.depthChartId &&
                        d.position === item.position &&
                        d.rank === item.rank &&
                        d.teamId === item.teamId
                )

                if (currentItem) {
                    const startPositionDoNotSave: DTO.DepthChartItem = {
                        ...currentItem,
                        depthChartId: '',
                    }
                    const { position, teamId, rank, ...currentItemInfo } = currentItem

                    const movedItemToSave: DTO.DepthChartItem = {
                        position: newLocation.position,
                        teamId: newLocation.teamId,
                        rank: newLocation.rank,
                        ...currentItemInfo,
                    }

                    let allDepthChartItems: DTO.DepthChartItem[] = []

                    if (existingItem) {
                        const [itemsToUpdate, allOtherItems] = [
                            // lower items at the same position as selectedItem
                            depthChart.items.filter(
                                (d) =>
                                    d.depthChartId === newLocation.depthChartId &&
                                    d.position === newLocation.position &&
                                    d.teamId === newLocation.teamId &&
                                    d.rank >= newLocation.rank &&
                                    (d.depthChartId !== item.depthChartId ||
                                        d.position !== item.position ||
                                        d.teamId !== item.teamId ||
                                        d.rank !== item.rank)
                            ),
                            depthChart.items.filter(
                                (d) =>
                                    (d.depthChartId !== newLocation.depthChartId ||
                                        d.position !== newLocation.position ||
                                        d.teamId !== newLocation.teamId ||
                                        d.rank < newLocation.rank) &&
                                    (d.depthChartId !== item.depthChartId ||
                                        d.position !== item.position ||
                                        d.teamId !== item.teamId ||
                                        d.rank !== item.rank)
                            ),
                        ]

                        const ranks = itemsToUpdate.map((d) => d.rank).sort((a, b) => a - b)

                        const mappedItemsToUpdate = itemsToUpdate
                            .sort((a, b) => a.rank - b.rank)
                            .map((d) => {
                                const lowerRanks = ranks.filter((r) => r <= d.rank)
                                if (ranks.length > 0) {
                                    if (lowerRanks.length === d.rank - ranks[0] + 1) {
                                        const { rank: r, ...rest } = d
                                        return { rank: d.rank + 1, ...rest }
                                    }
                                }
                                return d
                            })
                        allDepthChartItems = [...mappedItemsToUpdate, ...allOtherItems, movedItemToSave]
                    } else {
                        const allOtherItems = depthChart.items.filter(
                            (d) =>
                                d.depthChartId !== item.depthChartId ||
                                d.position !== item.position ||
                                d.teamId !== item.teamId ||
                                d.rank !== item.rank
                        )
                        allDepthChartItems = [...allOtherItems, movedItemToSave]
                    }
                    if (allDepthChartItems.length !== depthChart.items.length) {
                        toastContext?.addToast({
                            severity: 'error',
                            message: 'Something Went Wrong. Refresh the Page and Try Again',
                        })
                    } else if (allDepthChartItems.map((d) => d.rank).includes(7)) {
                        toastContext?.addToast({
                            severity: 'warning',
                            message: 'Max 6 Players Per Position',
                        })
                    } else {
                        const updatedDepthChartItems = allDepthChartItems.filter(
                            (d) =>
                                (d.teamId === currentItem.teamId && d.position === currentItem.position) ||
                                (d.teamId === newLocation.teamId && d.position === newLocation.position)
                        )
                        move([...updatedDepthChartItems, startPositionDoNotSave], {
                            onSuccess: () => {
                                toastContext?.addToast({
                                    severity: 'success',
                                    message: 'Update Successful',
                                })
                            },
                        })
                    }
                    setSelectedItems({ type: 'move', item: null })
                    setSelectedItems({ type: 'edit', item: null })
                }
            }
        },
        [depthChart, move, toastContext]
    )
    return {
        selectedItems,
        setSelectedItems,
        handleMoveItem,
    }
}

export default useDepthChartHelpers
