export const isNumber = (val: unknown): val is number => {
    if (val === undefined || val === null) return false
    if (typeof val === 'boolean') return false
    if (typeof val === 'string' && !val.trim().length) return false
    if (Array.isArray(val)) return false
    // @ts-expect-error TS only allows number values in isNaN even thought all types are allowed
    return !isNaN(val) // eslint-disable-line no-restricted-globals
}

export const asNumber = (val: unknown): number | null => (isNumber(val) ? Number(val) : null)

export const safeRatio = (val: number | null | undefined, total: number | null | undefined): number | null => {
    if (!isNumber(val)) return null
    if (!isNumber(total) || total === 0) return null
    return val / total
}
